import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from "../../../config/service";

const TaskDetailsDialog = (props) => {

    let filterCriteria = { limit: 20, page: 1, criteria: [{ key: "taskID", value: props.neededTaskID, type: "eq" }], direction: 'desc', sortfield: 'created' };
    let apiUrl = `tasks?filter=${JSON.stringify(filterCriteria)}`;
    let taskData = {};
    let [finalResponse, setFinalResponse] = useState({})
    let [filteredResponse, setFilteredResponse] = useState({})

    let taskScreenKeys = [
        { dbKey: 'name', label: 'Task' },
        { dbKey: 'description', label: 'Description' },
        { dbKey: 'actualWorkingHours', label: 'Worked Hours' },
        { dbKey: 'assignedToName', label: 'Assigned To' },
        { dbKey: 'createdByName', label: 'Created By' },
        { dbKey: 'estimatedHours', label: 'Estimated Hours' },
        { dbKey: 'taskStatus', label: 'Task Status' },
        { dbKey: 'priority', label: 'Priority' },
        { dbKey: 'sprintSearch', label: 'Sprint' },
        { dbKey: 'screen', label: 'Screen' },
        { dbKey: 'taskID', label: 'Task ID' }
    ]

    useEffect(() => {
        fetchMethodRequest('GET', apiUrl)
            .then(async (response) => {
                if (response) {
                    taskData = response.tasks[0];
                    let finalResponse = {}
                    taskScreenKeys.forEach(element => {
                        if (taskData[element.dbKey]) {
                            finalResponse[element.label] = taskData[element.dbKey]
                        }
                    });

                    if (finalResponse && finalResponse.hasOwnProperty('Description')) {
                        const { Description, ...remainingResponse } = finalResponse;
                        setFilteredResponse({ Description });
                        setFinalResponse(remainingResponse);
                    }
                    else { setFinalResponse(finalResponse) }


                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }, [props.neededTaskID])

    const isEmptyObject = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }


    return (
        <>
            <div className="card flex justify-content-center">
                <Dialog visible={props.visibleTaskDetails} modal header="Task Details" style={{ width: '50rem' }} onHide={() => props.setVisibleTaskDetails(false)}>

                    <div>
                        {Object.keys(finalResponse).map((key, index) => (
                            <div key={index}>
                                <strong>{key}:</strong> {finalResponse[key]}
                            </div>
                        ))}

                        {!isEmptyObject(filteredResponse) && (
                            <>
                                <strong>Description:</strong>
                                <div style={{ border: '1px solid black', padding: '10px' }}>
                                    <div dangerouslySetInnerHTML={{ __html: filteredResponse.Description }} />
                                </div>
                            </>
                        )}

                    </div>

                    <Button color="primary" onClick={() => { props.setOpenTaskDetailsDialog(false); props.setVisibleTaskDetails(false); props.setNeededTaskID('') }} style={{ marginTop: '40px', marginLeft: '340px' }}>
                        {'Close'}
                    </Button>
                </Dialog >
            </div >
        </>
    )

}
export default TaskDetailsDialog;