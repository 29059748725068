import React from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoCKEditorField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    let field = props.field;
    const placeholder = props.placeholder;

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color="red"
                icon={faAsterisk}
                style={{ width: "7px", marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    let onChangeText = (event, editor) => {
        const data = editor.getData();
        if(field){
        field.onChange(data);
        }
        if(props.ckEditorData){
            props.setCkEditorData(data);
        }
        if (props.getEditorText) {
            props.getEditorText(data)
        }
    }

    return (
        <div className="flex flex-column">
            <div className="flex align-center">
                <label htmlFor={id} className="text-capitalize"> {label} </label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <CKEditor
                editor={ClassicEditor}
                data={field? field.value : props.ckEditorData? ckEditorData : null}
                onChange={(event, editor) => onChangeText(event, editor)}
                placeholder={placeholder}
            />
        </div>
    );
};

export default DoCKEditorField;
