import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Inventories = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Inventories");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        filter: true,
        displayInSettings: true,
        fieldType: "Text",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedBy=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Type",
        derivedValue: "type=type=type=type=type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        defaultValues: ['Laptop', 'Ipad', 'MacBook', 'Furniture', 'Desktop', 'Projector', 'Others'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "operatingSystem",
        fieldName: "operatingSystem",
        type: "dropDown",
        placeholder: "Operating System",
        value: "",
        label: "Operating System",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Operating System",
        derivedValue:
          "operatingSystem=operatingSystem=operatingSystem=operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        defaultValues: ['Windows', 'Ubuntu', 'MacOS'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "operatingSystem",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "brand",
        fieldName: "brand",
        type: "dropDown",
        placeholder: "Brand",
        value: "",
        label: "Brand",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Brand",
        derivedValue: "brand=brand=brand=brand=Brand=Brand=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        defaultValues: ['Lenovo', 'Dell', 'HP', 'Samsung', 'Microsoft', 'Apple', 'MI', 'Acer', 'ASUS', 'MSI', 'Sony'],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "brand",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        fieldName: "name",
        type: "uniqueField",
        placeholder: "Serial Number",
        value: "",
        label: "Serial Number",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Serial Number",
        derivedValue:
          "sNo=sNo=sNo=sNo=sNo=sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: true,
        isAddToIndex: true,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "sNo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "invoiceNumber",
        fieldName: "invoiceNumber",
        type: "text",
        placeholder: "Invoice Number",
        value: "",
        label: "Invoice Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Invoice Number",
        derivedValue:
          "invoiceNumber=invoiceNumber=invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "invoiceNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDate",
        fieldName: "purchaseDate",
        type: "date",
        placeholder: "Purchase Date",
        value: "",
        label: "Purchase Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Purchase Date",
        derivedValue:
          "purchaseDate=purchaseDate=purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "purchaseDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue: "status=status=status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        label0: "InStock",
        color0: "primary",
        value0: "InStock",
        label1: "Assigned",
        color1: "success",
        value1: "Assigned",
        label2: "Repair",
        color2: "warning",
        value2: "Repair",
        label3: "Sold",
        color3: "secondary",
        value3: "Sold",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "Assigned To",
        value: "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Assigned To",
        derivedValue:
          "assignedTo=assignedTo=assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["name"],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDocumentLink",
        fieldName: "purchaseDocumentLink",
        type: "text",
        placeholder: "Purchase Document Link",
        value: "",
        label: "Purchase Document Link",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Purchase Document Link",
        derivedValue:
          "purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "purchaseDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedDocumentLink",
        fieldName: "assignedDocumentLink",
        type: "text",
        placeholder: "Assigned Document Link",
        value: "",
        label: "Assigned Document Link",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Assigned Document Link",
        derivedValue:
          "assignedDocumentLink=assignedDocumentLink=assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "assignedDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Description",
        derivedValue:
          "Description=Description=Description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "text",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Comments",
        derivedValue: "comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "type=type=type=type=type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "operatingSystem",
        fieldName: "operatingSystem",
        type: "dropDown",
        placeholder: "Operating System",
        value: "",
        label: "Operating System",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue:
          "operatingSystem=operatingSystem=operatingSystem=operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "brand",
        fieldName: "brand",
        type: "dropDown",
        placeholder: "Brand",
        value: "",
        label: "Brand",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "brand=brand=brand=brand=Brand=Brand=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sNo",
        fieldName: "sNo",
        type: "uniqueField",
        placeholder: "Serial Number",
        value: "",
        label: "Serial Number",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue:
          "sNo=sNo=sNo=sNo=sNo=sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: true,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "invoiceNumber",
        fieldName: "invoiceNumber",
        type: "text",
        placeholder: "Invoice Number",
        value: "",
        label: "Invoice Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue:
          "invoiceNumber=invoiceNumber=invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "purchaseDate",
        fieldName: "purchaseDate",
        type: "date",
        placeholder: "Purchase Date",
        value: "",
        label: "Purchase Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue:
          "purchaseDate=purchaseDate=purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "status=status=status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: true,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        label0: "InStock",
        color0: "primary",
        value0: "InStock",
        label1: "Assigned",
        color1: "success",
        value1: "Assigned",
        label2: "Repair",
        color2: "warning",
        value2: "Repair",
        label3: "Sold",
        color3: "secondary",
        value3: "Sold",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "Assigned To",
        value: "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "assignedTo=assignedTo=assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "displayName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "purchaseDocumentLink",
        fieldName: "purchaseDocumentLink",
        type: "text",
        placeholder: "Purchase Document Link",
        value: "",
        label: "Purchase Document Link",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "assignedDocumentLink",
        fieldName: "assignedDocumentLink",
        type: "text",
        placeholder: "Assigned Document Link",
        value: "",
        label: "Assigned Document Link",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue:
          "assignedDocumentLink=assignedDocumentLink=assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue:
          "Description=Description=Description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "ckeditor",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created By",
        derivedValue: "createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated By",
        derivedValue: "updatedByName=updatedBy=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: "employee",
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "type",
        fieldName: "type",
        type: "dropDown",
        placeholder: "Type",
        value: "",
        label: "Type",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Type",
        derivedValue: "type=type=type=type=type=Type=Type=Type=Type=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Laptop", value: "Laptop", color: "secondary" },
          { label: "Ipad", value: "Ipad", color: "warning" },
          { label: "MacBook", value: "MacBook", color: "danger" },
          { label: "Furniture", value: "Furniture", color: "dark" },
          { label: "Desktop", value: "Desktop", color: "info" },
          { label: "Projector", value: "Projector", color: "success" },
          { label: "Others", value: "Others", color: "primary" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "type",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "operatingSystem",
        fieldName: "operatingSystem",
        type: "dropDown",
        placeholder: "Operating System",
        value: "",
        label: "Operating System",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Operating System",
        derivedValue:
          "operatingSystem=operatingSystem=operatingSystem=operatingSystem=OperatingSystem=OperatingSystem=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "operatingSystem",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Windows", value: "Windows", color: "success" },
          { label: "Ubuntu", value: "Ubuntu", color: "info" },
          { label: "MacOS", value: "MacOS", color: "danger" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "operatingSystem",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "brand",
        fieldName: "brand",
        type: "dropDown",
        placeholder: "Brand",
        value: "",
        label: "Brand",
        width: "130px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Brand",
        derivedValue: "brand=brand=brand=brand=Brand=Brand=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "brand",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "Lenovo", value: "Lenovo", color: "secondary" },
          { label: "Dell", value: "Dell", color: "success" },
          { label: "HP", value: "HP", color: "danger" },
          { label: "Samsung", value: "Samsung", color: "warning" },
          { label: "Microsoft", value: "Microsoft", color: "info" },
          { label: "Apple", value: "Apple", color: "danger" },
          { label: "MI", value: "MI", color: "dark" },
          { label: "Acer", value: "Acer", color: "secondary" },
          { label: "ASUS", value: "ASUS", color: "success" },
          { label: "MSI", value: "MSI", color: "warning" },
          { label: "Sony", value: "Sony", color: "info" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "brand",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sNo",
        fieldName: "sNo",
        type: "uniqueField",
        placeholder: "Serial Number",
        value: "",
        label: "Serial Number",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Serial Number",
        derivedValue:
          "sNo=sNo=sNo=sNo=sNo=sNo=SerialNumber=SerialNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "sNo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: true,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "sNo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "invoiceNumber",
        fieldName: "invoiceNumber",
        type: "text",
        placeholder: "Invoice Number",
        value: "",
        label: "Invoice Number",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Invoice Number",
        derivedValue:
          "invoiceNumber=invoiceNumber=invoiceNumber=InvoiceNumber=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "invoiceNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "invoiceNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDate",
        fieldName: "purchaseDate",
        type: "date",
        placeholder: "Purchase Date",
        value: "",
        label: "Purchase Date",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Purchase Date",
        derivedValue:
          "purchaseDate=purchaseDate=purchaseDate=PurchaseDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "purchaseDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Status",
        derivedValue: "status=status=status=Status=Status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        fieldType: "dropDown",
        filterElement: [
          { label: "InStock", value: "InStock", color: "primary" },
          { label: "Assigned", value: "Assigned", color: "success" },
          { label: "Repair", value: "Repair", color: "warning" },
          { label: "Sold", value: "Sold", color: "secondary" },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        label0: "InStock",
        color0: "primary",
        value0: "InStock",
        label1: "Assigned",
        color1: "success",
        value1: "Assigned",
        label2: "Repair",
        color2: "warning",
        value2: "Repair",
        label3: "Sold",
        color3: "secondary",
        value3: "Sold",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedTo",
        fieldName: "assignedTo",
        type: "relateAutoComplete",
        placeholder: "Assigned To",
        value: "",
        label: "Assigned To",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Assigned To",
        derivedValue:
          "assignedTo=assignedTo=assignedTo=AssignedTo=AssignedTo=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedTo",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: 1001,
        isClickable: false,
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "employee",
        searchApi: "employees",
        isMultiple: false,
        textAlign: "center",
        show: true,
        field: "assignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "purchaseDocumentLink",
        fieldName: "purchaseDocumentLink",
        type: "text",
        placeholder: "Purchase Document Link",
        value: "",
        label: "Purchase Document Link",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Purchase Document Link",
        derivedValue:
          "purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=purchaseDocumentLink=PurchaseInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "purchaseDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "purchaseDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "assignedDocumentLink",
        fieldName: "assignedDocumentLink",
        type: "text",
        placeholder: "Assigned Document Link",
        value: "",
        label: "Assigned Document Link",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Assigned Document Link",
        derivedValue:
          "assignedDocumentLink=assignedDocumentLink=assignedDocumentLink=AssignedInventoryLink=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "assignedDocumentLink",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "assignedDocumentLink",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Description",
        derivedValue:
          "Description=Description=Description=Description=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "text",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Comments",
        derivedValue: "comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "comments",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        isClickable: false,
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value ) => {
    const { getDataFromServer } = await dataTableRef.current;
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.inventories}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            getDataFromServer();
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
    {
      name: "InStock",
      action: saveDataToServer,
      options: [
        {
          label: "InStock",
          field: "status",
          value: "InStock",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Assigned",
      action: saveDataToServer,
      options: [
        {
          label: "Assigned",
          field: "status",
          value: "Assigned",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Repair",
      action: saveDataToServer,
      options: [
        {
          label: "Repair",
          field: "status",
          value: "Repair",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
    {
      name: "Sold",
      action: saveDataToServer,
      options: [
        {
          label: "Sold",
          field: "status",
          value: "Sold",
          show: rolePermission?.actions?.includes("Approve/Reject") ? true : false,
          multiple: false,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Inventories'
          // apiUrl={apiCalls.Inventories}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.inventories}
          globalSearch={"type/sNo/invoiceNumber/assignedTo"}
          displayName="Inventories"
          type="Inventories"
          routeTo={apiCalls.inventories}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.inventories?.toLowerCase()}
          apiUrl={apiCalls.inventories}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="inventories"
          apiUrl={apiCalls.inventories}
        />
      ) : null}
    </span>
  );
};

export default Inventories;