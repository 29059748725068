import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InventoryHistories from './components/InventoryHistories';

const inventoryHistories = (props,{ t }) => (
  <Container>
    <InventoryHistories {...props} />
  </Container>
);

inventoryHistories.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(inventoryHistories);
