import React, { useEffect, useState } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import classNames from 'classnames';
import fetchMethodRequest from '../../config/service';
import config from '../../config/config';
import dateFormats from '../UI/FormatDate/formatDate';
import '../../scss/component/tabs.scss';

const HistoryView = (props) => {
    let [activeTab, setActiveTab] = useState('1');
    let [getComments, setGetComments] = useState([]);

    const historyData = props.historyData;
    const type = props.type
    useEffect(() => {
        getCommentsData();
    }, [props.selectedId])

    let toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    let getCommentsData = async () => {
        fetchMethodRequest('GET', `${type.toLowerCase()}/${props.selectedId}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && response.comments) {
                    let comments = response.comments;
                    setGetComments(comments)
                }
            }).catch((err) => {
                return err
            })
    }

    return (
        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }}>
            <div className="tabs__wrap">
                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1') }}>{'History'}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2') }}>{'Comments'}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classNames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3') }}>{'Attachments'}</NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                    <TabPane tabId="1">
                        <div style={{ maxHeight: 400 }}>
                            {historyData && historyData.length > 0 ?
                                historyData.map((item, i) => {
                                    return (
                                        <div key={i} className='row  p-2 m-0' >
                                            <div className="timeline__content col-sm-10">
                                                <div className='row justify-content-between'>
                                                    <h5 className='col-sm-auto text-capitalize'>
                                                        {item.createdBy && item.createdBy.employee ?
                                                            item.createdBy.employee.displayName
                                                            : item.email}
                                                    </h5>
                                                    <h6 className='col-sm-auto'> {item.created}</h6>
                                                    <p className='col-12' style={{ paddingTop: "5px" }}
                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="timeline__icon ml-3">
                                                {item.created ?
                                                    <img src={`${config.imgUrl}employee/${item.created}`} className='detailsImgStyle '
                                                    />
                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                    </TabPane>

                    <TabPane tabId="2">
                        <div style={{ maxHeight: 600 }}>
                            {getComments && getComments.length > 0 ?
                                getComments.map((item, i) => {
                                    return (
                                        <div key={i} className='row  p-2 m-0'>
                                            <div className="timeline__content col-sm-10">
                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdByName}</h5>
                                                    <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDayMonthFormat)}</h6>
                                                    <p className='col-12 mt-1' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                </div>
                                            </div>
                                            <div className="timeline__icon ml-3">
                                                {/* {item.createdBy.employee.photo ?
                            <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                            />
                            : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                          } */}
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                            }
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div >
    )

}
export default HistoryView;