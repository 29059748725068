import React, { useState,useEffect } from 'react';
import { Button, CardBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link,Navigate } from 'react-router-dom';

import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
// Validate input
import validate from '../../../Validations/validate';
// Input field
import DefaultInput from '../../../../shared/components/form/DefaultInput';

// Config
import configMessage from '../../../../config/configMessages';
import fetchRequest from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import PasswordField from '@/shared/components/form/password';

// Loader
import Loader from '../../../App/Loader';
import jwt_decode from "jwt-decode"
import ReCAPTCHA from "react-google-recaptcha";
import {
  browserName,
  osName, osVersion, deviceType
} from "react-device-detect";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckBoxField from '@/shared/components/form/CheckBox2';
import { Checkbox } from 'primereact/checkbox';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';

const publicIp = require('public-ip');
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

const LogInForm = (props) => {

  const [deviceInfo, setDeviceInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(config.displayRecaptcha ? true : false);
  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRobot, setIsRobot] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const [remember_me, setRemember_me] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [displayDetailsModal, setDisplayDetailsModal] = useState(false);
  const formFields = [{ "name": "name", "placeholder": "Name", "label": "Name", "type": "text" }, { "name": "email", "placeholder": "Email", "label": "Email", "type": "email" }, { "name": "phone", "placeholder": "Phone", "label": "Phone", "type": "text" }, { "name": "States", "placeholder": "States", "label": "States", "type": "text" }]
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const [isForVerification, setIsForVerification] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect( () => {
    getSettingsData();
    let ipAddr = '';
    publicIp.v4().then((res) => {
      ipAddr = res;
    })
    setDeviceInfo({
      ipAddress:ipAddr,
      browserName:browserName,
      osName:osName,
      osVersion:osVersion,
      deviceType:deviceType
    })
    getLoginRememberInfo();
  },[])
  if (isLoginSuccess) {
    window.history.replaceState(null, null, '/tasks');
  }

  const loadGoogleComponent = (self) => {
    function handleCredentialResponse(response) {
      var data = jwt_decode(response.credential);
      if (data.email && data.name && config.displayGoogleLogin) {
        return fetchRequest('POST', 'auth/socialLogin', { name: data.name, email: data.email, role: "Admin", entityType: config.entityType, deviceInfo: deviceInfo })
          .then(async (response) => {
            if (response && response.respCode && response.respCode == 200) {
              self.setState({
                isLoading: true
              });
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (response.accessToken) {
                  let tokenInfo = {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken,
                    tokenExpires: response.tokenExpires,
                  };
                  userDetails = { ...userDetails, ...tokenInfo };

                  // save user credentials in storage
                  localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                  localStorage.removeItem('sessionexpired');
                }
                if (userDetails && userDetails.rolePermissions) {
                  let permissions = userDetails.rolePermissions;
                  // save user permissions in storage
                  localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                }
              }

              showToasterMessage(response.respMessage, 'success');

              if (userDetails.firstTimeLogin) {
                self.setState({
                  // isLoginSuccess: true,
                  displayDetailsModal: true,
                  isLoading: false,
                  userDetails: userDetails
                })
                if (self.props.load) {
                  setTimeout(() => {
                    self.props.load(self.state.userDetails)
                  }, 300);
                }

              } else {
                self.setState({
                  isLoginSuccess: true,

                })
              }
            } else if (response && response.errorMessage) {
              setTimeout(() => {
                self.setState({
                  isLoginDisabled: false,
                  isLoading: false
                })
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
            // settingsAction
          }).catch((err) => {
            self.setState({
              isLoginDisabled: false,
              isLoading: false
            });
          });
      }
    }
    if (config.displayGoogleLogin) {
      // window.onload = function () {
      if (self.state.settingsData && self.state.settingsData.GoogleClientId) {
        let GoogleClientId = self.state.settingsData && self.state.settingsData.GoogleClientId ? self.state.settingsData.GoogleClientId : null
        /* global google */
        google.accounts.id.initialize({
          client_id: GoogleClientId,
          callback: handleCredentialResponse
        });
        google.accounts.id.renderButton(
          document.getElementById("loginButton"),
          { theme: "outline", size: "large", width: document.getElementById('googleButton').clientWidth }  // customization attributes
        );
        // google.accounts.id.prompt(); // also display the One Tap dialog
      }
      // }
    }
    return true
  }

  const  getLoginRememberInfo = async () => {
    let keysToRemove = ["loginCredentials", "rolePermissions", 'loginBody', 'sessionexpired'];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }

    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    reset(loginRememberInfo);
    setLoginRememberInfo(loginRememberInfo);
    setRemember_me(loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false);
  }

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  const submit = (values) => {
    if (values.email && values.password) {
      setRemember_me(values.remember_me);
      setIsLoginDisabled(true);
      setIsLoading(true);
      handleLoginUser(values);
    }
  }

  const handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType,
      deviceInfo: deviceInfo
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
    if (captchaValue || !config.displayRecaptcha) {
      return fetchRequest('POST', apiCalls.loginUser, userBody)
        .then(async (response) => {
          if (response) {
            setIsLoading(true);

            if (response.details || response.respCode) {
              let userDetails = response.details;
              if (userDetails && userDetails._id) {
                if (userDetails.isTwoFactorAuthentication) {
                  localStorage.setItem('employee', JSON.stringify(userDetails))
                  setIsForVerification(true)
                } else {
                  if (response.accessToken) {
                    let tokenInfo = {
                      accessToken: response.accessToken,
                      refreshToken: response.refreshToken,
                      tokenExpires: response.tokenExpires,
                    };
                    userDetails = { ...userDetails, ...tokenInfo };
                    // save user credentials in storage
                    localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                    localStorage.removeItem('sessionexpired');
                    if (userDetails && userDetails.role && userDetails.role == 'Teacher') {
                      setLoginRole(userDetails.role);
                    }
                  }
                  if (userDetails && userDetails.rolePermissions) {
                    let permissions = userDetails.rolePermissions;
                    // save user permissions in storage
                    localStorage.setItem('rolePermissions', JSON.stringify(permissions));
                  }
                  showToasterMessage(response.respMessage, 'success');
                  setIsLoginSuccess(true);
                }
              }
              if (remember_me) {
                var data = {
                  email: values.email,
                  password: values.password,
                  remember_me: remember_me
                }
                if (loginRememberInfo && loginRememberInfo.email &&
                  loginRememberInfo.email != values.email) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                } else if (!loginRememberInfo) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                }
              } else {
                localStorage.removeItem('loginRememberInfo');
              };
            }
            else if (response && response.errorMessage) {
              setTimeout(() => {
                setIsLoginDisabled(false);
                setIsLoading(false);
              }, 250);
              showToasterMessage(response.errorMessage, 'error');
            }
          }
          // settingsAction
        }).catch((err) => {
          setIsLoginDisabled(false);
          setIsLoading(false);
        });
    }
  }


  const onChange = (value) => {
    if (value == 'remember') {
      setRemember_me(!remember_me);
    } if (config.displayRecaptcha) {
      setCaptchaValue(value && value != "remember" ? value : null);
      setIsLoginDisabled(value && value != "remember" ? false : true)
    }
  }
  const getSettingsData = () => {
      let settingsData;
      fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        settingsData = response.settings[0]
        settingsData["GoogleClientId"]=settingsData["Admin"].GoogleClientId;
        settingsData["GooglesecretKey"]=settingsData["Admin"].GooglesecretKey;
        setSettingsData(settingsData);
      }
    })
    return settingsData;
  }
  const cancelReset = async type => {
    setIsLoginSuccess(false);
    setDisplayDetailsModal(false);
    localStorage.removeItem("rolePermissions");
    localStorage.removeItem("loginCredentials");
    await this.props.reset();
  }
  const handleModalSubmit = async formValues => {
    if (formValues) {
      delete formValues.email;
      delete formValues.password;
      formValues["firstTimeLogin"] = false
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls["employee"]}/${user._id}`;
      }
      return fetchRequest('PUT', Url, formValues)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            setIsLoginSuccess(true);
            setDisplayDetailsModal(false);
          } else if (response && response.errorMessage) {
            setIsLoginSuccess(false);
            setDisplayDetailsModal(false);
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          setIsLoginSuccess(false);
          setDisplayDetailsModal(false);
          return err;
        });

    } else {
      setIsLoginSuccess(false);
      setDisplayDetailsModal(false);
      return;
    }

  }

  return (
    <div>
      {displayDetailsModal ?
        <Modal
          isOpen={displayDetailsModal}
          toggle={cancelReset}
          centered
          className={`modal-dialog modal-dialog-centered modal-dialog--primary  modal-dialog--header`}
        >
          <ModalHeader className="modal__header">
            <button
              className="lnr lnr-cross modal__close-btn"
              type="button"
              onClick={cancelReset}
            />
            <p className="bold-text  modal__title"> {"Details"} </p>
          </ModalHeader>
          <ModalBody className="p-2">
            <Loader loader={isLoading} />

            <form className="form" onSubmit={handleSubmit(handleModalSubmit)}>
              <div className="row mx-1 mt-3 " style={{ width: '100%' }}>

                {
                  formFields && formFields.length && formFields.length > 0 ?
                    formFields.map((item, index) => {
                      return (
                        <div className="col-sm-12 col-md-6">
                          <div className="form__form-group ">
                            <span className="form__form-group-label">{item.label}</span>
                            <div className="form__form-group-field">
                              <Controller
                                name="email"
                                control={control}
                                render={({ field, fieldState }) => (
                                  <DoInputField
                                    input={field}
                                    id={field.id}
                                    name={field.name}
                                    field={field}
                                    type="email"
                                    fieldState={fieldState}
                                    errors={errors}
                                    placeholder="Email"
                                  />)}
                              />

                            </div>
                          </div>
                        </div>

                      )
                    }) : null

                }
                <div className="col-sm-12 text-center pt-3">
                  <div>
                    <Button
                      outline
                      color="primary"
                      type="buttom"
                      onClick={cancelReset}
                    >
                      Cancel
                    </Button>

                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        :

        <form className="form" onSubmit={handleSubmit(submit)}>
          <Loader loader={isLoading} />
          <div className="form__form-group mt-2">

            <div>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState }) => (
                  <DoInputField
                    input={field}
                    id={field.id}
                    name={field.name}
                    label={field.name}
                    field={field}
                    type="email"
                    fieldState={fieldState}
                    errors={errors}
                    placeholder="Email"
                  />)}
              />

            </div>
          </div>
          <div className="form__form-group mt-2">

            <div >
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState }) => (
                  <DoPasswordField
                    input={field}
                    name={field.name}
                    field={field}
                    fieldState={fieldState}
                    errors={errors}
                    placeholder="Password"
                  />)}
              />
            </div>
          </div>

          <div className="form__form-group my-2">
            <div className='d-flex justify-content-between'>
              <div>
                <Controller
                  name="remember_me"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <Checkbox inputId={field.name} checked={remember_me} inputRef={field.ref}  onChange={(e) => onChange('remember')} />
                      <label htmlFor={field.name} className='ms-1'>Remember Me</label>
                    </>
                  )}
                />

              </div>
              <div >
                <Link to="/forgot_password">Forgot password?</Link>
              </div>
            </div>

          </div>
          <div className="w-100 mt-2">
            {config.displayRecaptcha && settingsData &&settingsData.GooglesecretKey ?
              < ReCAPTCHA
                sitekey={settingsData && settingsData.GooglesecretKey}
                // sitekey={"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"}
                onChange={onChange}
                type="image"
                className='recap'
              />
              : null}
          </div>
          <div className="account__btns mt-3" style={{ width: '100%' }}>
            <Button className="ml-auto" style={{ width: '100%' }}
              color="primary"
              disabled={isLoginDisabled}
            >LOGIN</Button>
          </div>
          {config.displayGoogleLogin ?
            <div className='line'>
              <div className='text'>
                or
              </div>
            </div>
            : null
          }
          <div className='d-flex flex-column w-100 pb-3'>
            {config.displayGoogleLogin ?
              <div id="googleButton">
                <div id="loginButton" className="strike w-100"></div>
              </div>
              : null
            }
          </div>
          {/* <div className="form__form-group mb-2" >
            <div className="form__form-group-field" style={{ float: 'left' }}>
              Dont' have an account ?&nbsp;<Link to="/register">Register</Link>
            </div>
          </div> */}
          {/* change */}
          {/* Navigate to Home */}
          {isForVerification ? <Navigate to="/two_step_verification" /> : null}
          {isLoginSuccess ? <Navigate to="/dashboards" /> : null}
        </form >

      }
    </div>
  );
}
export default LogInForm;
