import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import fetchMethodRequest from "../../../config/service";

const WorkedHoursModal = (props) => {
    const [workedHoursData, setWorkedHoursData] = useState('');
    const [tableFields, setTableFields] = useState([
        { textAlign: 'center', width: 45, field: 'Sno', header: 'S.No.' },
        { textAlign: 'left', width: 100, field: 'employeeName', header: 'Employee Name' },
        { textAlign: 'left', width: 100, field: 'role', header: 'Role' },
        { textAlign: 'center', width: 80, field: 'totalHours', header: 'Working Hours' },
    ]);

    useEffect(() => {
        fetchMethodRequest('GET', `timeSheets/employeeWise/${props.editRowDataID}`)
            .then(async (response) => {
                if (response) {
                    const workedHours = response.map((obj, index) => {
                        if (obj._id) {
                            return { ...obj, ...obj._id, Sno: index + 1 };
                        } else {
                            return { ...obj, Sno: index + 1 };
                        }
                    });
                    setWorkedHoursData(workedHours)
                    showToasterMessage(response.successMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }, [])

    return (
        <div className="card flex justify-content-center">
            <Dialog visible={props.visible} modal header="Employee Working Hours" style={{ width: '50rem' }} onHide={() => props.setVisible(false)}>
                <DataTable
                    value={workedHoursData}
                    tableStyle={{ minWidth: '30rem', marginTop: '20px' }}
                >
                    {tableFields && tableFields.length > 0 ?
                        tableFields.map((item, i) => {
                            return <Column key={item.field + i}
                                style={{
                                    maxWidth: item.width, padding: '4px',
                                }}
                                bodyStyle={{
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textAlign: item.textAlign ? item.textAlign : 'left'
                                }}
                                field={item.field}
                                header={item.header}
                                headerStyle={{ fontWeight: '520', width: item.width, fontSize: 14, color: 'black', }}
                            />
                        })
                        : null}
                </DataTable>

                <Button color="primary" onClick={props.closeWorkedHoursModal} style={{ marginTop: '40px', marginLeft: '340px' }}>
                    {'Close'}
                </Button>
            </Dialog >
        </div >
    );

}

export default WorkedHoursModal;
