import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Issues = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Issues");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "ScreenName",
        type: "text",
        placeholder: "Screen Name",
        label: "Screen Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 10,
        header: "Screen Name",
        derivedValue: "ScreenName=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "ScreenName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "ScreenName",
        showOrHideFields: [],
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "Project",
        type: "relateAutoComplete",
        placeholder: "Project",
        label: "Project",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Project",
        derivedValue: "Project=Project=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Project",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "ProjectName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "Project",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Project_ProjectName",
        type: "text",
        placeholder: "Project_ProjectName",
        label: "Project_ProjectName",
        header: "Project ProjectName",
        width: 110,
        parentField: "Project",
        id: "Project_ProjectName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "ProjectName",
        show: true,
        field: "Project_ProjectName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task",
        type: "relateAutoComplete",
        placeholder: "Task",
        label: "Task",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Task",
        derivedValue: "Task=Task=Task=Task=Task=Task=Task=Task=Task=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Task",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Task",
        hasDependency: "true",
        fieldForKey: "Projects",
        hasDependencyField: "Project",
        searchField: "TaskSummary",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["TaskSummary", "ScreenorModuleName"],
        controllerName: "Task",
        searchApi: "tasks",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "Task",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task_TaskSummary",
        type: "text",
        placeholder: "Task_TaskSummary",
        label: "Task_TaskSummary",
        header: "Task TaskSummary",
        width: 110,
        parentField: "Task",
        id: "Task_TaskSummary",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "TaskSummary",
        show: true,
        field: "Task_TaskSummary",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task_ScreenorModuleName",
        type: "text",
        placeholder: "Task_ScreenorModuleName",
        label: "Task_ScreenorModuleName",
        header: "Task ScreenorModuleName",
        width: 110,
        parentField: "Task",
        id: "Task_ScreenorModuleName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "ScreenorModuleName",
        show: true,
        field: "Task_ScreenorModuleName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Status",
        derivedValue: "Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "New", color: "success" },
          { label: "UAT", value: "UAT", color: "info" },
          { label: "UAT", value: "UAT", color: "dark" },
          { label: "Reopen", value: "Reopen", color: "secondary" },
          { label: "Fixed", value: "Fixed", color: "success" },
          { label: "InProgress", value: "InProgress", color: "success" },
          { label: "Closed", value: "Closed", color: "warning" },
          {
            label: "Clarification required",
            value: "Clarification required",
            color: "secondary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "Status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "AssignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "AssignedTo",
        derivedValue: "AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "AssignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1004,
        searchField: "role",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["role"],
        controllerName: "Roles",
        searchApi: "roles",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "AssignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Roles_role",
        type: "text",
        placeholder: "Roles_role",
        label: "Roles_role",
        header: "Roles role",
        width: 110,
        parentField: "AssignedTo",
        id: "Roles_role",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "role",
        show: true,
        field: "Roles_role",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "StartDate",
        type: "date",
        placeholder: "StartDate",
        label: "StartDate",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "StartDate",
        derivedValue: "StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "StartDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "StartDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EndDate",
        type: "date",
        placeholder: "EndDate",
        label: "EndDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "EndDate",
        derivedValue: "EndDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EndDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "EndDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Description",
        derivedValue: "Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EstimatedHours",
        type: "text",
        placeholder: "EstimatedHours",
        label: "EstimatedHours",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "EstimatedHours",
        derivedValue: "EstimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EstimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "EstimatedHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IsClientAdded",
        type: "checkbox",
        placeholder: "IsClientAdded",
        label: "IsClientAdded",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "IsClientAdded",
        derivedValue: "IsClientAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IsClientAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "IsClientAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "issueScreenshot",
        type: "profile",
        placeholder: "IssueScreenshot",
        label: "IssueScreenshot",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "IssueScreenshot",
        derivedValue: "issueScreenshot=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "issueScreenshot",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Issues",
        textAlign: "Center",
        show: true,
        field: "issueScreenshot",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "profile",
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "ScreenName",
        type: "text",
        placeholder: "Screen Name",
        label: "Screen Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 10,
        derivedValue: "ScreenName=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "ScreenName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "Project",
        type: "relateAutoComplete",
        placeholder: "Project",
        label: "Project",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "Project=Project=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Project",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "ProjectName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Task",
        type: "relateAutoComplete",
        placeholder: "Task",
        label: "Task",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "Task=Task=Task=Task=Task=Task=Task=Task=Task=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Task",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Task",
        hasDependency: "true",
        fieldForKey: "Projects",
        hasDependencyField: "Project",
        searchField: "TaskSummary",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["TaskSummary", "ScreenorModuleName"],
        controllerName: "Task",
        searchApi: "tasks",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "New", color: "success" },
          { label: "UAT", value: "UAT", color: "info" },
          { label: "UAT", value: "UAT", color: "dark" },
          { label: "Reopen", value: "Reopen", color: "secondary" },
          { label: "Fixed", value: "Fixed", color: "success" },
          { label: "InProgress", value: "InProgress", color: "success" },
          { label: "Closed", value: "Closed", color: "warning" },
          {
            label: "Clarification required",
            value: "Clarification required",
            color: "secondary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "AssignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "AssignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1004,
        searchField: "role",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["role"],
        controllerName: "Roles",
        searchApi: "roles",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "StartDate",
        type: "date",
        placeholder: "StartDate",
        label: "StartDate",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "StartDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "EndDate",
        type: "date",
        placeholder: "EndDate",
        label: "EndDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "EndDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EndDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "EstimatedHours",
        type: "text",
        placeholder: "EstimatedHours",
        label: "EstimatedHours",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "EstimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EstimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "IsClientAdded",
        type: "checkbox",
        placeholder: "IsClientAdded",
        label: "IsClientAdded",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "IsClientAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IsClientAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "issueScreenshot",
        type: "profile",
        placeholder: "IssueScreenshot",
        label: "IssueScreenshot",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "issueScreenshot=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "issueScreenshot",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Issues",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "ScreenName",
        type: "text",
        placeholder: "Screen Name",
        label: "Screen Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 10,
        header: "Screen Name",
        derivedValue: "ScreenName=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "ScreenName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "ScreenName",
        showOrHideFields: [],
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#f17038",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "Project",
        type: "relateAutoComplete",
        placeholder: "Project",
        label: "Project",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Project",
        derivedValue: "Project=Project=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Project",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1007,
        searchField: "ProjectName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["ProjectName"],
        controllerName: "Project",
        searchApi: "projects",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "Project",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Project_ProjectName",
        type: "text",
        placeholder: "Project_ProjectName",
        label: "Project_ProjectName",
        header: "Project ProjectName",
        width: 110,
        parentField: "Project",
        id: "Project_ProjectName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "ProjectName",
        show: true,
        field: "Project_ProjectName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task",
        type: "relateAutoComplete",
        placeholder: "Task",
        label: "Task",
        width: "150px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Task",
        derivedValue: "Task=Task=Task=Task=Task=Task=Task=Task=Task=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Task",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Task",
        hasDependency: "true",
        fieldForKey: "Projects",
        hasDependencyField: "Project",
        searchField: "TaskSummary",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["TaskSummary", "ScreenorModuleName"],
        controllerName: "Task",
        searchApi: "tasks",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "Task",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task_TaskSummary",
        type: "text",
        placeholder: "Task_TaskSummary",
        label: "Task_TaskSummary",
        header: "Task TaskSummary",
        width: 110,
        parentField: "Task",
        id: "Task_TaskSummary",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "TaskSummary",
        show: true,
        field: "Task_TaskSummary",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Task_ScreenorModuleName",
        type: "text",
        placeholder: "Task_ScreenorModuleName",
        label: "Task_ScreenorModuleName",
        header: "Task ScreenorModuleName",
        width: 110,
        parentField: "Task",
        id: "Task_ScreenorModuleName",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "ScreenorModuleName",
        show: true,
        field: "Task_ScreenorModuleName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Status",
        derivedValue: "Status=Status=Status=Status=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "New", value: "New", color: "success" },
          { label: "UAT", value: "UAT", color: "info" },
          { label: "UAT", value: "UAT", color: "dark" },
          { label: "Reopen", value: "Reopen", color: "secondary" },
          { label: "Fixed", value: "Fixed", color: "success" },
          { label: "InProgress", value: "InProgress", color: "success" },
          { label: "Closed", value: "Closed", color: "warning" },
          {
            label: "Clarification required",
            value: "Clarification required",
            color: "secondary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "Status",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "AssignedTo",
        type: "relateAutoComplete",
        placeholder: "AssignedTo",
        label: "AssignedTo",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "AssignedTo",
        derivedValue: "AssignedTo=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "AssignedTo",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1004,
        searchField: "role",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["role"],
        controllerName: "Roles",
        searchApi: "roles",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "AssignedTo",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Roles_role",
        type: "text",
        placeholder: "Roles_role",
        label: "Roles_role",
        header: "Roles role",
        width: 110,
        parentField: "AssignedTo",
        id: "Roles_role",
        displayinlist: "true",
        fieldType: "RACSubField",
        textAlign: "Center",
        subFieldName: "role",
        show: true,
        field: "Roles_role",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "StartDate",
        type: "date",
        placeholder: "StartDate",
        label: "StartDate",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "StartDate",
        derivedValue: "StartDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "StartDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "StartDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EndDate",
        type: "date",
        placeholder: "EndDate",
        label: "EndDate",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "EndDate",
        derivedValue: "EndDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EndDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "EndDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Description",
        type: "textarea",
        placeholder: "Description",
        label: "Description",
        width: "200px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Description",
        derivedValue: "Description=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "EstimatedHours",
        type: "text",
        placeholder: "EstimatedHours",
        label: "EstimatedHours",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "EstimatedHours",
        derivedValue: "EstimatedHours=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "EstimatedHours",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "EstimatedHours",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "IsClientAdded",
        type: "checkbox",
        placeholder: "IsClientAdded",
        label: "IsClientAdded",
        width: "120px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "IsClientAdded",
        derivedValue: "IsClientAdded=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "IsClientAdded",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "IsClientAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "issueScreenshot",
        type: "profile",
        placeholder: "IssueScreenshot",
        label: "IssueScreenshot",
        width: "120px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "IssueScreenshot",
        derivedValue: "issueScreenshot=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "issueScreenshot",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        imagePath: "Issues",
        textAlign: "Center",
        show: true,
        field: "issueScreenshot",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "profile",
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.issues}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Issues'
          // apiUrl={apiCalls.Issues}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.issues}
          globalSearch={"ScreenName/Status/Description/EstimatedHours"}
          displayName="Issues"
          type="Issues"
          routeTo={apiCalls.issues}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.issues?.toLowerCase()}
          apiUrl={apiCalls.issues}
          selectedId={params.id}
          displayActionsInTable={true}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="issues"
          apiUrl={apiCalls.issues}
        />
      ) : null}
    </span>
  );
};

export default Issues;
