import React from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoInputField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : '';
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const isDisable = props.isDisable ? true : false
    const style = props.style ? props.style : null;

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

   const onChange = (e) => {
     field.onChange(e.target.value)
     if (props.onChange) {
       props.onChange(e)
     }
   }

    return (
        <div className="flex flex-column">
             <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>
            <InputText 
            type={type}
            onKeyDown={(e) => {
              // Check if the type is 'number' before preventing the 'e' key
              if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
                e.preventDefault();
              }
            }} 
            value={field.value}
            id={id} 
            name={name}
            disabled={isDisable} 
            placeholder={placeholder}
            aria-describedby="username-help" 
            style={style}
            onChange={onChange} /> 
            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
                  
        </div>
    )

}

export default DoInputField;